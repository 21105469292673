import { useNavigate } from "react-router-dom";
import ListaEmisiones from "./ListaEmisiones";

const BienvenidaEmisiones = () => {
  const navigate = useNavigate();

  return (
    <div className="bienvenida">
      <div className="titulo-bienvenida">
        <h1>CALCULADORA DE EMISIONES DE CARBONO</h1>
      </div>
      <div className="contenido-bienvenida">
        <h2>DESCUBRE EL</h2>
        <h2>
          <span>IMPACTO AMBIENTAL</span>
        </h2>
        <h2>
          <span>DE TUS ACTIVIDADES</span>
        </h2>
        <p>
          BASADA EN LA CALCULADORA DE EMISIONES DEL <span>RENE</span>
        </p>
        <p>(Registro Nacional de Emisiones)</p>
      </div>
      <div className="button">
        <button
          onClick={() => navigate("/CalculadoraEmisiones/AgregarEmision ")}
        >
          Comenzar
        </button>
      </div>
      <div className="footer">
        <h3>Barrendo Mexicano</h3>

        <img src="/Assets/emisiones/berrendo.png" alt="Agregar" />
      </div>
    </div>
  );
};

export default BienvenidaEmisiones;
