const CintilloSubsidio = () => {
  return (
    <>
    <div className="subsidio">
      <div className="subsidio-header">
        <div className="title-subsidio">
          <div className="title-container">
            <h2><span>+</span>  Subsidio</h2>  
          </div>
          <div className="icon-subsidio">
            <img src={"/Assets/Modalidades/blanco/subsidio.svg"} alt="icono" /> 
          </div>
        </div>
      </div>
      <div className="elementos-subsidio">
        <div className="encabezado-subsidio">
          <div className="titulo">Para</div>
          <div className="container">
            <p>Adquisicion de maquinaria y equipo</p>
          </div>
        </div>
        <div className="encabezado-subsidio">
          <div className="titulo">Monto</div>
          <div className="container">
            <p>
              Hasta <span>$40,000.00 </span>
            </p>
          </div>
        </div>
        <div className="encabezado-subsidio">
          <div className="titulo">Beneficiarios</div>

          <div className="container">
            <p>
              Microempresas acreditadas de la sub modalidad micro credito
              empresarial{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="leyenda-subsidio">
        <h1>SIN GARANTIAS-SIN INTERESES-UN SOLO APOYO POR EMPRESA</h1>
        </div>
      </div>
    </>

  );
};

export default CintilloSubsidio;
