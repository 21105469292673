import { TextField } from "@mui/material";
import { useState } from "react";
import { multiply, round } from "mathjs";
const CalculadoraEmisiones = () => {
  const [cosnumoElectrico, setCosnumoElectrico] = useState(0);
  const [consumoGasolina, setConsumoGasolina] = useState(0);
  const calcularEmisionesElectricas = (consumo) => {
    setCosnumoElectrico((consumo * 0.435) / 1000);
  };
  const calcularEmisionesGasolina = (consumo) => {
    setConsumoGasolina((consumo * 2.39) / 1000);
  };
  function formato(numero) {
    if (String(numero) === "NaN" || parseFloat(numero) === 0) {
      return 0;
    }
    // Convertir el número a una cadena de texto y dividirlo en partes enteras y decimales
    var partes = round(numero, 2).toString().split(".");
    // Tomar la parte entera del número
    var parteEntera = partes[0];
    // Tomar la parte decimal del número si existe
    var parteDecimal = partes.length > 1 ? "." + partes[1] : "";

    // Agregar separador de miles a la parte entera utilizando una expresión regular
    parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Devolver el número con separador de miles
    return parteEntera + parteDecimal;
  }
  return (
    <div className="calculadora-emisiones">
      <h2>Calculadora de Emisiónes de Carbono.</h2>
      <h3>Ingresa tus emisiones</h3>

      <TextField
        onChange={(e) => {
          const valor = e.target.value;
          if (valor >= 0) {
            // Asegura que el valor es no negativo
            calcularEmisionesElectricas(valor);
          }
        }}
        label="Consumo electrico anual en kWh"
        type="number"
        sx={{
          "& .MuiInputBase-input": {
            color: "#056b74",
          },
          "& .MuiInputLabel-root": {
            color: "#056b74",
          },
        }}
      />
      <TextField
        onChange={(e) => calcularEmisionesGasolina(e.target.value)}
        label="Consumo de gasolina anual en litros"
        type="number"
        sx={{
          "& .MuiInputBase-input": {
            color: "#056b74",
          },
          "& .MuiInputLabel-root": {
            color: "#056b74",
          },
        }}
      />
      <div className="contenido-emisiones">
        <div className="consumos">
          <div className="subtotal">
            <img src="/Assets/emisiones/electrico.png" alt="lighting" />
            <div className="resultados">
              <p>HUELLA DE CARBONO POR CONSUMO ELECTRICO:</p>
              <h4> {formato(cosnumoElectrico)} T/CO₂</h4>
            </div>
          </div>
          <div className="subtotal">
            <img src="/Assets/emisiones/gasolina.png" alt="fire" />
            <div className="resultados">
              <p>HUELLA DE CARBONO POR CONSUMO DE GASOLINA:</p>
              <h4> {formato(consumoGasolina)} T/CO₂</h4>
            </div>
          </div>
          <div className="subtotal">
            <img src="/Assets/emisiones/arboles.png" alt="trees" />
            <div className="resultados">
              <p>PARA ABSORBER ESTA CANTIDAD DE CO2 SE NECESITAN ENTRE:</p>
              <h3>
                <strong>
                  {formato(
                    round(((cosnumoElectrico + consumoGasolina) * 1000) / 30, 0)
                  )}
                </strong>
                <strong> y </strong>
                <strong>
                  {formato(
                    round(((cosnumoElectrico + consumoGasolina) * 1000) / 10, 0)
                  )}
                </strong>{" "}
                <span>
                  <strong>Árboles</strong>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="border"></div>
      <div className="total">
        <img src="/Assets/emisiones/co2.png" alt="co2" />
        <div className="resultados">
          <h3>Tu huella de carbono total:</h3>
          <p> {formato(cosnumoElectrico + consumoGasolina)} toneladas CO₂</p>
        </div>
      </div>
    </div>
  );
};

export default CalculadoraEmisiones;
