import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Emisiones = () => {

  return (
    <section className="emisiones">
    
    <Outlet/>
    </section>
  );
};

export default Emisiones;
